import { Button, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import ApiEmpresas from "../../../../js/api/global/Empresas";
import ApiInventarios from "../../../../js/api/global/Inventarios";
import ApiSucursals from "../../../../js/api/global/Sucursals";
import ApiUsuarios from "../../../../js/api/global/Usuarios";
import { InventoriesContext } from "../../../../js/app/context/InventoriesContext";
import { openNotification } from "../../../controls/Notifications";
import InventoriesFilter from "./partial/InventoriesFilter";
import InventoriesInsert from "./partial/InventoriesInsert";
import InventoriesList from "./partial/InventoriesList";
import InventoriesView from "./partial/InventoriesView";
import helperSession from "../../../../js/helpers/helpersSession";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Inventories = () => {
  const [loading, setLoading] = useState(false);
  const [isInventoryView, setIsInventoryView] = useState(false);
  const [inventorySelected, setInventorySelected] = useState(null);

  const [inventories, setInventories] = useState([]);
  const [idSucursalSelected, setIdSucursalSelected] = useState("");

  const [parametersData, setParametersData] = useState({});

  const isAdmin = helperSession.GetAccount("rol") === "Admin";
  const isSupervisor = helperSession.GetAccount("rol") === "Supervisor";

  useEffect(() => {
    setLoading(true);

    Promise.all([
      ApiEmpresas.GetEmpresaList(false),
      ApiSucursals.GetSucursalList(false),
      ApiUsuarios.GetUsuariosContador(),
      ApiInventarios.GetInventarioList(-1),
    ]).then((res) => {
      setLoading(false);
      const empresas = res[0];
      const sucursales = res[1];
      const usuarios = res[2];
      const inventarios = res[3];

      if (
        empresas.success &&
        sucursales.success &&
        usuarios.success &&
        inventarios.success
      ) {
        const parameters = {
          sucursales: sucursales.dataList,
          empresas: empresas.dataList,
          usuarios: usuarios.dataList,
        };
        setParametersData(parameters);
        setInventories(inventarios.dataList);
      } else {
        openNotification(
          "e",
          "Carga de datos",
          "Ha ocurrido un error al cargar la data"
        );
      }
    });
  }, []);

  useEffect(() => {
    if (idSucursalSelected !== "") {
      setLoading(true);
      ApiInventarios.GetInventarioList(idSucursalSelected).then((resp) => {
        setLoading(false);
        setInventories([...resp.dataList]);
      });
    }
  }, [idSucursalSelected]);

  const handleGoBack = () => {
    setIsInventoryView(false);
    setInventorySelected(null);
  };

  return (
    <div className={`container-${isInventoryView ? "s" : "xl"}`}>
      <h3>Inventarios</h3>
      {isInventoryView && (
        <Row justify="start" align="middle" style={{ marginBottom: "10px" }}>
          <Col>
            <Button
              type="link"
              onClick={handleGoBack}
              icon={<ArrowLeftOutlined />}
            >
              Volver
            </Button>
          </Col>
        </Row>
      )}
      <Divider dashed />
      <div>
        <InventoriesContext.Provider
          value={{
            inventories,
            setInventories,
            idSucursalSelected,
            setIdSucursalSelected,
            parametersData,
            loading,
            setLoading,
          }}
        >
          {isInventoryView ? (
            <InventoriesView
              {...{
                inventorySelected,
              }}
            />
          ) : (
            <>
              <InventoriesFilter />
              <Divider dashed />
              {(isAdmin || isSupervisor) && idSucursalSelected && (
                <InventoriesInsert />
              )}
              <InventoriesList
                {...{ setIsInventoryView, setInventorySelected }}
              />
            </>
          )}
        </InventoriesContext.Provider>
      </div>
    </div>
  );
};

export default Inventories;

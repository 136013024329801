import { Col, Row, Spin } from "antd";
import React from "react";

export const Widgets = ({ widgets }) => {
  const textStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <Row
      gutter={16}
      style={{
        backgroundColor: "#FAFAFA",
        padding: "16px 24px",
        marginBottom: "20px",
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#D9D9D6",
      }}
    >
      {widgets.map((widget, index) => (
        <Col key={index} xs={24} sm={12} md={8}>
          <div
            style={{
              padding: "8px",
              margin: "8px",
              background: "#fff",
              borderRadius: "8px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#D9D9D6",
            }}
          >
            <h3 style={textStyle}>{widget.title}</h3>
            {widget.value === undefined || widget.value === "" ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Spin />
              </div>
            ) : (
              <h1 style={textStyle}>{widget.value}</h1>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
};

import React, { useState } from "react";
import { Button, InputNumber } from "antd";
import ApiInventarioProductos from "../../../../../js/api/global/InventarioProductos";
import { openNotification } from "../../../../controls/Notifications";
import { toFixInteger } from "../../../../../js/helpers/helpers";
import helperSession from "../../../../../js/helpers/helpersSession";
const CountInventorieInsertDetail = ({
  producto,
  setProducto,
  resetCodigoBarra,
}) => {
  const [loading, setLoading] = useState(false);
  const isAccountant = helperSession.GetAccount("rol") === "Contador";
  const isAdmin = helperSession.GetAccount("rol") === "Admin";
  const isSupervisor = helperSession.GetAccount("rol") === "Supervisor";

  const [quantyProduct, setQuantyProduct] = useState(0);

  const updateQuanty = (isSum) => {
    if (!loading && !(isAccountant && quantyProduct === 0)) {
      setLoading(true);

      const { idProductoSucursal, idInventario } = producto;

      const cantidad = isAccountant
        ? parseInt(quantyProduct)
        : isSum
        ? producto.cantidad + parseInt(quantyProduct)
        : parseInt(quantyProduct);

      const productToSave = {
        idProductoSucursal,
        idInventario,
        medidad: "",
        categoria: "",
        cantidad,
      };

      ApiInventarioProductos.PostInventarioProducto(productToSave).then(
        (resp) => {
          if (resp.success) {
            setLoading(false);
            openNotification(
              "s",
              "Guardado exitoso",
              "Se ha guardado correctamente"
            );
            resetCodigoBarra();
            setProducto(null);
          } else {
            openNotification("e", "Error de guardado", resp.message);
          }
        }
      );
    }
  };
  if (!producto) return "";
  return (
    <div>
      Producto : <b>{producto.productoSucursal} </b> <br />
      Categoria : {producto.categoria} <br />
      Código : {producto.codigoBarra} <br />
      Código maestra : {producto.codigoMaestra} <br />
      Cantidad : {producto.cantidad} <br />
      {producto.usuario && (
        <>
          {" "}
          Última mod.: {producto.usuario} <br />
        </>
      )}
      <InputNumber
        parser={(value) => toFixInteger(value)}
        maxLength={6}
        min={0}
        autoFocus={true}
        defaultValue={0}
        onPressEnter={isAccountant && updateQuanty}
        onChange={(x) => setQuantyProduct(x)}
        value={quantyProduct}
      />{" "}
      <Button
        type="primary"
        loading={loading}
        onClick={() => updateQuanty(true)}
        disabled={!quantyProduct}
      >
        Sumar
      </Button>{" "}
      {(isAdmin || isSupervisor) && (
        <Button
          type="primary"
          loading={loading}
          onClick={() => updateQuanty(false)}
          disabled={!quantyProduct < 0}
        >
          Actualizar
        </Button>
      )}
    </div>
  );
};

export default CountInventorieInsertDetail;

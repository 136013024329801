import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tag } from "antd";
import DateTable from "../../../../controls/DateTable";
import ApiInventarioProductos from "../../../../../js/api/global/InventarioProductos";
import ApiInventarios from "../../../../../js/api/global/Inventarios";
import fileDownload from "js-file-download";
import { createPriceColumn } from "../../../../helpers/tableHelpers";
import InventoriesProductEdit from "./InventoriesProductEdit";
import { Widgets } from "../../../../controls/Widgets";
import { formatCurrencyCLP } from "../../../../utils/formatPrices";
import { openNotification } from "../../../../controls/Notifications";

const InventoriesView = ({ inventorySelected }) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inventario, setInventario] = useState([]);
  const [isProductUpdated, setIsProductUpdated] = useState(false);

  let diferencia, totalFisico, totalTeorico;

  if (inventario?.[0]?.resultadoPrecios) {
    ({ diferencia, totalFisico, totalTeorico } =
      inventario[0].resultadoPrecios);
  }

  const categoriasUnicas = {};
  inventario.forEach((producto) => {
    categoriasUnicas[producto.categoria] = true;
  });
  const categoriesFilter = Object.keys(categoriasUnicas).map((categoria) => {
    return { text: categoria, value: categoria };
  });

  const renderEstadoProducto = (inventario) => {
    let color = "";
    switch (inventario.estado) {
      case "OK":
        color = "green";
        break;
      case "DIFERENCIA BAJA":
        color = "blue";
        break;
      case "DIFERENCIA MEDIA - REVISAR":
        color = "orange";
        break;
      case "DIFERENCIA ALTA - REVISAR":
        color = "red";
        break;
      default:
        color = "default"; // Color predeterminado
        break;
    }
    return <Tag color={color}>{inventario.estado}</Tag>;
  };

  const editProductoSucursal = (producto) => {
    const { precioUnitario, cantidad, cantidadTeorica } = producto || {};
    if (precioUnitario == null || cantidad == null || cantidadTeorica == null) {
      openNotification(
        "e",
        "No se puede editar",
        " El producto no se ha ingresado al inventario"
      );
      setIsEditVisible(false);
      setProductSelected(null);
    } else {
      setProductSelected(producto);
      setIsEditVisible(true);
    }
  };

  const columns = [
    {
      title: "Código de barra",
      dataIndex: "codigoBarra",
      key: "codigoBarra",
      search: true,
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
      key: "categoria",
      onFilter: (value, record) => record.categoria.indexOf(value) === 0,
      filters: categoriesFilter,
    },
    {
      title: "Producto",
      dataIndex: "productoSucursal",
      key: "productoSucursal",
      search: true,
    },
    {
      title: "Fecha",
      dataIndex: "fechaFormateada",
      key: "fechaFormateada",
      search: true,
    },
    {
      title: "Código maestra",
      dataIndex: "codigoMaestra",
      key: "codigoMaestra",
      search: true,
    },
    {
      title: "Cantidad Teórica",
      dataIndex: "cantidadTeorica",
      key: "cantidadTeorica",
      search: true,
    },
    {
      title: "Cantidad Física",
      dataIndex: "cantidad",
      key: "cantidad",
      search: true,
    },
    {
      title: "Diferencia",
      dataIndex: "diferencia",
      key: "diferencia",
      search: true,
    },
    {
      title: "Porcentaje Diferencia",
      dataIndex: "porcentajeDiferencia",
      key: "porcentajeDiferencia",
      search: true,
    },
    createPriceColumn("Precio Unitario", "precioUnitario", "precioUnitario"),
    createPriceColumn("Total Físico", "precioTotalFisico", "totalFisico"),
    createPriceColumn("Total Teórico", "precioTotalTeorico", "totalTeorico"),
    createPriceColumn(
      "Diferencia Precio",
      "diferenciaPrecioProducto",
      "diferenciaPrecio"
    ),
    {
      title: "Estado Producto",
      render: renderEstadoProducto,
      filters: [
        {
          text: "DIFERENCIA ALTA",
          value: "DIFERENCIA ALTA - REVISAR",
        },
        {
          text: "DIFERENCIA MEDIA",
          value: "DIFERENCIA MEDIA - REVISAR",
        },
        {
          text: "DIFERENCIA BAJA",
          value: "DIFERENCIA BAJA",
        },
        {
          text: "DIFERENCIA NEGATIVA - REVISAR",
          value: "DIFERENCIA NEGATIVA - REVISAR",
        },
        {
          text: "OK",
          value: "OK",
        },
        {
          text: "N/A",
          value: "N/A",
        },
      ],
      onFilter: (value, record) => record.estado === value,
    },
    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (producto) => (
        <Button type="primary" onClick={() => editProductoSucursal(producto)}>
          Editar
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (true) {
      setLoading(true);
      ApiInventarioProductos.GetInventarioProductoSelectedById(
        inventorySelected.id
      ).then((resp) => {
        setLoading(false);
        setInventario([...resp.data]);
      });
    }
  }, [inventorySelected, isProductUpdated]);

  const downloadInventories = () => {
    // pasar el row inventario desde el componente anterior
    ApiInventarios.GetInventarioToDownload(inventorySelected.id).then(
      (resp) => {
        const { empresa, sucursal, correlativo, fechaFormateada } =
          inventorySelected;
        const fileName = `Inventario ${empresa} (${sucursal}) N${correlativo} - ${fechaFormateada}.csv`;
        fileDownload(resp, fileName);
      }
    );
  };

  const widgetsData = [
    {
      title: "Total físico",
      value: formatCurrencyCLP(totalFisico, "currency"),
    },
    {
      title: "Total teórico",
      value: formatCurrencyCLP(totalTeorico, "currency"),
    },
    {
      title: "Diferencia precio",
      value: formatCurrencyCLP(diferencia, "currency"),
    },
  ];

  return (
    <>
      <Widgets widgets={widgetsData} />
      <Row justify="end" align="middle" style={{ marginBottom: "10px" }}>
        <Col>
          <Button
            type="primary"
            onClick={() => downloadInventories(inventario)}
          >
            Descargar
          </Button>
        </Col>
      </Row>
      <br />
      <DateTable
        data={inventario}
        columns={columns}
        keyValue="id"
        loading={loading}
      />
      {productSelected && (
        <InventoriesProductEdit
          isVisible={isEditVisible}
          setIsVisible={setIsEditVisible}
          productSelected={productSelected}
          setProductSelected={setProductSelected}
          isProductUpdated={isProductUpdated}
          setIsProductUpdated={setIsProductUpdated}
        />
      )}
    </>
  );
};

export default InventoriesView;

import { Card, Form, Input, Spin } from "antd";
import React, { useRef, useState } from "react";
import ApiInventarioProductos from "../../../../../js/api/global/InventarioProductos";
import { openNotification } from "../../../../controls/Notifications";
import CountInventorieInsertDetail from "./CountInventorieInsertDetail";

const { Item } = Form;

const CountInventorieSearch = ({ inventorie }) => {
  const [loading, setLoading] = useState(false);
  const [producto, setProducto] = useState(null);
  const [codigoBarra, setCodigoBarra] = useState("");

  const codigoBarraRef = useRef();

  const resetCodigoBarra = () => {
    codigoBarraRef.current.focus();
    setCodigoBarra("");
  };

  const handdlerChangeCodigoBarra = (e) => {
    const insetValue = e.target.value ?? "";
    setCodigoBarra(insetValue);

    if (insetValue.length > 5) {
      setLoading(true);
      ApiInventarioProductos.GetInventarioProductoSelected(
        inventorie.id,
        insetValue
      ).then((resp) => {
        setLoading(false);
        if (resp.success) {
          setProducto(resp.data);
        } else {
          setProducto(null);
          openNotification("e", "Error de búsqueda", resp.message);
        }
      });
    }
  };

  if (!inventorie) return <Card loading={true}></Card>;

  return (
    <>
      <br />
      <Card>
        <Item label="Código de barra" initialValue="">
          <Input
            autoFocus={true}
            onPressEnter={handdlerChangeCodigoBarra}
            ref={codigoBarraRef}
            value={codigoBarra}
            onChange={handdlerChangeCodigoBarra}
          />
        </Item>
        <br />
        {loading ? (
          <Spin />
        ) : (
          producto && (
            <CountInventorieInsertDetail
              producto={producto}
              setProducto={setProducto}
              resetCodigoBarra={resetCodigoBarra}
            />
          )
        )}
      </Card>
    </>
  );
};

export default CountInventorieSearch;

import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { BranchOfficesContext } from "../../../../../js/app/context/BranchOfficesContext";
import { toOptions } from "../../../../../js/helpers/helpers";
import { branchOfficesInsertRules } from "../../../../../js/validations/admin/BranchOfficesInsertRules";
import ApiSucursals from "../../../../../js/api/global/Sucursals";

const { Item } = Form;

const BranchOfficesInsert = () => {
  const [loading, setLoading] = useState(false);

  const [comunasOptions, setComunasOptions] = useState([]);

  const { parametersData, setBranchOffices, branchOffices } = useContext(
    BranchOfficesContext
  );

  const { regions, comunas, empresas } = parametersData;

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    ApiSucursals.PostSucursal(data.branchOffice).then((resp) => {
      setLoading(false);
      if (resp.success) {
        setBranchOffices([...branchOffices, resp.data]);

        openNotification(
          "s",
          "Inserción correcta",
          "Se ha ingresado la sucursal correctamente"
        );
        formRef.current.resetFields();
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };

  const changeRegion = (value) => {
    const comunasFiltered = comunas.filter((x) => x.idRegion === value) ?? [];
    formRef.current.setFields([
      { name: ["branchOffice", "idComuna"], value: "" },
    ]);
    setComunasOptions(comunasFiltered);
  };

  return (
    <Form
      name="addbranchOffice"
      layout="vertical"
      requiredMark={false}
      ref={formRef}
      onFinish={submitForm}
    >
      <Row gutter={[24, 16]}>
        <Col md={12} xs={24}>
          <Item
            name={["branchOffice", "idEmpresa"]}
            label="Empresa"
            rules={branchOfficesInsertRules.idEmpresa}
          >
            <Select
              options={toOptions(empresas, "id", "nombre")}
              loading={!empresas}
            ></Select>
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["branchOffice", "nombre"]}
            label="Nombre"
            rules={branchOfficesInsertRules.nombre}
          >
            <Input maxLength={25} />
          </Item>
        </Col>

        <Col md={12} xs={24}>
          <Item
            label="Region"
            name={["branchOffice", "idRegion"]}
            rules={branchOfficesInsertRules.region}
          >
            <Select
              options={toOptions(regions, "id", "nombre")}
              loading={!regions}
              onChange={changeRegion}
            ></Select>
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["branchOffice", "idComuna"]}
            label="Comuna"
            rules={branchOfficesInsertRules.comuna}
          >
            <Select
              options={toOptions(comunasOptions, "id", "nombre")}
              loading={!comunas}
            ></Select>
          </Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BranchOfficesInsert;
